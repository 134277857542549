import ProgressBar from "react-topbar-progress-indicator";
import { Component } from "react";

class AjaxLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading : false
        }
    }
    loadingStarted() {
        this.setState({
          loading: true
        });
    }
    loadingFinished() {
        this.setState({
            loading: false
        });
    }

    render() {
        return (
            <>
                {this.state.loading && <ProgressBar />}
            </>
        )
    }
}

export default AjaxLoader;
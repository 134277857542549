import {Component, createRef, StrictMode} from 'react';
import Routes from './routes/Routes.js';
import axios from 'axios';
import AjaxLoader from './element-components/AjaxLoader.js';
import {initiateLogOut} from './pages/Logout.jsx';
import "./scss/style.scss";

class App extends Component {
    constructor(props) {
        super(props);
        this.ajaxLoader = createRef();
        this.state = {ajaxLoader: this.ajaxLoader};
        //////// interceptor to forward cookies and show loader ////////
        axios.interceptors.request.use((config) => {
            if (config.headers && config.headers.dontintercept) {
                return config;
            }
            if (typeof config.withCredentials == "undefined") {
                config.withCredentials = true;
            }
            if (typeof config.showLoader == "undefined" || config.showLoader) {
                if (this.state.ajaxLoader.current) {
                    this.state.ajaxLoader.current.loadingStarted();
                }
            }
            return config;
        },
        (error) => {
            if (this.state.ajaxLoader.current) {
                this.state.ajaxLoader.current.loadingFinished();
            }
            return Promise.reject(error);
        });
        ////////////////////////////////////////////////////////////////
        ////// interceptor to finish loader and show login popup  //////
        axios.interceptors.response.use((resp) => {
            if (resp.config && resp.config.headers && resp.config.headers.dontintercept) {
                return resp;
            }
            if (this.ajaxLoader.current) {
                this.ajaxLoader.current.loadingFinished();
            }
            if (resp.data && !resp.data.success) {
                if (resp.data.msg && window.showErrorNotification) {
                    alert(resp.data.msg);
                }
            }
            return resp;
        }, (error) => {
            if (error.config && error.config.headers && error.config.headers.dontintercept) {
                return error;
            }
            if (this.ajaxLoader.current) {
                this.ajaxLoader.current.loadingFinished();
            }
            if (error.response && error.response.status === 401) {
                alert("You are logged out")
                setTimeout(function() {
                    initiateLogOut(true);
                }, 5000);
            }
            else if (error.response && error.response.status === 403) {
                alert("You are not authorized to do that.");
            }
            else {
                // alert("Something went wrong.");
            }
            return Promise.reject(error);
        });
        ////////////////////////////////////////////////////////////////
        if (window.cache === undefined) {
            window.cache = {};
        }
        if (window.localStorage.preferences === undefined) {
            window.localStorage.preferences = '{}';
        }
    }

    render = () => {
        return (
            <StrictMode>
                <Routes/>
                <AjaxLoader ref={this.ajaxLoader} />
            </StrictMode>
        );
    }
}

export default App;